import { getString, formatString } from "./strings";
import { decodeURLQueryString } from "./utils";

class Resources {
  get Edit() {
    return getString("EDIT", "Edit");
  }
  get Delete() {
    return getString("DELETE", "Delete");
  }
  get Ok() {
    return getString("OK", "Ok");
  }
  get Save() {
    return getString("SAVE", "Save");
  }
  get Submit() {
    return getString("SUBMIT", "Submit");
  }
  get Send() {
    return getString("SEND", "Send");
  }
  get Done() {
    return getString("DONE", "Done");
  }
  get Cancel() {
    return getString("CANCEL", "Cancel");
  }
  get View() {
    return getString("VIEW", "View");
  }
  get Upload() {
    return getString("UPLOAD", "Upload");
  }
  get Select() {
    return getString("SELECT", "Select");
  }
  get Search() {
    return getString("SEARCH", "Search");
  }
  get Hide() {
    return getString("HIDE", "Hide");
  }
  get Show() {
    return getString("SHOW", "Show");
  }
  get Due() {
    return getString("DUE", "Due");
  }
  get DatePaid() {
    return getString("DATE_PAID", "Date paid");
  }
  get DueToday() {
    return getString("DUE_TODAY", "Due Today");
  }
  get DueTomorrow() {
    return getString("DUE_TOMORROW", "Due Tomorrow");
  }
  OverdueBy(days) {
    return formatString(getString("OVERDUE_BY", "Overdue by {days} days"), { days });
  }
  get OverdueByOneDay() {
    return getString("OVERDUE_BY_ONE_DAY", "Overdue by 1 day");
  }
  get SignIn() {
    return getString("SIGN_IN", "Sign In");
  }
  get LoginTitle() {
    return getString("LOGIN_TITLE", "Login");
  }
  get Name() {
    return getString("NAME", "Name");
  }
  get Filter() {
    return getString("FILTER", "Filter");
  }
  get Documents() {
    return getString("DOCUMENTS", "Documents");
  }
  get Profile() {
    return getString("PROFILE", "Profile");
  }
  YouDontHavePermissions(verb, resource) {
    return formatString(
      getString(
        "YOU_DONT_HAVE_PERMISSIONS",
        "You don't have permission to {verb} the {resource}. It may be a feature available only to account administrators."
      ),
      {
        verb,
        resource
      }
    );
  }

  get AccountFetchFailure() {
    return getString("ACCOUNT_FETCH_FAILED", "Fetching of your account data failed.  Please try again.");
  }
  get CompanyInfoFetchFailure() {
    return getString("COMPANY_INFO_FETCH_FAILURE", "Fetching information for the company failed. Please try again.");
  }
  get UserLoginFailure() {
    return getString("USER_LOGIN_FAILURE", "Login failed. Please verify your information and try again.");
  }
  get EmailAddress() {
    return getString("EMAIL_ADDRESS", "Email Address");
  }
  get Invoice() {
    return getString("INVOICE", "Invoice");
  }
  get Invoices() {
    return getString("INVOICES", "Invoices");
  }
  get OpenInvoices() {
    return getString("OPEN_INVOICES", "Open Invoices");
  }
  get ClosedInvoices() {
    return getString("CLOSED_INVOICES", "Closed Invoices");
  }
  get PastDueInvoices() {
    return getString("PAST_DUE_INVOICES", "Past Due Invoices");
  }
  get TotalAccountBalance() {
    return getString("TOTAL_ACCOUNT_BALANCE", "Total account balance");
  }  
  get AccountBalanceHelperText() {
    return getString(
      "ACCOUNT_BALANCE_HELPER_TEXT",
      "Your total account balance is updated to reflect any pending and unapplied payments you have made."
    );
  }
  get TotalPastDue() {
    return getString("TOTAL_PAST_DUE_AMOUNT", "Total Past Due");
  }
  get PastDueHelperText() {
    return getString(
      "PAST_DUE_HELPER_TEXT",
      "The sum of all overdue payments yet to be received."
    );
  }
  get MakeAPayment() {
    return getString("MAKE_A_PAYMENT", "Make a payment");
  }
  get Payments() {
    return getString("PAYMENTS", "Payments");
  }
  get PaymentAmount() {
    return getString("PAYMENT_AMOUNT", "Payment amount");
  }
  get PaymentMethod() {
    return getString("PAYMENT_METHOD", "Payment method");
  }
  get AddingPaymentMethod() {
    return getString("ADDING_PAYMENT_METHOD", "Adding payment method");
  }
  get AddedPaymentMethod() {
    return getString("ADDED_PAYMENT_METHOD", "Added payment method");
  }
  get AddingPaymentMethodFailed() {
    return getString("ADDING_PAYMENT_METHOD_FAILED", "Adding the payment method failed");
  }
  get PaymentDate() {
    return getString("PAYMENT_DATE", "Payment date");
  }
  get PaidFrom() {
    return getString("PAID_FROM", "Paid from");
  }
  get PaymentInfoFetchError() {
    return getString("PAYENT_INFO_FETCH_ERROR", "Fetching payment info failed.");
  }
  get MakePaymentError() {
    return getString("MAKE_PAYMENT_ERROR", "Making payment failed.");
  }
  get DeletePaymentMethodError() {
    return getString("DELETE_PAYMENT_METHOD_ERROR", "Deleting payment method failed.");
  }
  get BankAccount() {
    return getString("BANK_ACCOUNT", "Bank account");
  }
  get CreditCard() {
    return getString("CREDIT_CARD", "Credit card");
  }
  get ACH() {
    return getString("ACH", "ACH");
  }
  get SelectPaymentMethod() {
    return ("SELECT_PAYMENT_METHOD", "Select Payment Method");
  }
  get Expired() {
    return getString("EXPIRED", "Expired");
  }
  TotalInvoicedIn(year) {
    return formatString(getString("TOTAL_INVOICED_IN", "Total invoiced in {year}"), { year });
  }
  TotalPaidIn(year) {
    return formatString(getString("TOTAL_PAID_IN", "Total paid in {year}"), { year });
  }
  get TotalPaidHelperText() {
    return getString(
      "TOTAL_PAID_HELPER_TEXT",
      "Your total amount paid includes any pending and unapplied payments you have made."
    );
  }
  InvoicesSelectedForPayment(num) {
    return formatString(getString("INVOICES_SELECTED_FOR_PAYMENT", "Invoices selected for payment ({num})"), { num });
  }
  TotalPaymentAmount(num) {
    return formatString(getString("TOTAL_PAYMENT_AMOUNT", "Total Payment Amount: {num}"), { num });
  }
  TotalBalanceAmount(num) {
    return formatString(getString("TOTAL_BALANCE_AMOUNT", "Total Balance Amount: {num}"), { num });
  }
  TotalInvoiceAmount(num) {
    return formatString(getString("TOTAL_INVOICE_AMOUNT", "Total Invoice Amount: {num}"), { num });
  }
  AppliedCreditMemoAmount(num) {
    return formatString(getString("APPLIED_CREDIT_MEMO_AMOUNT", "Applied Credit Memo Amount: {num}"), { num });
  }
  TotalTransactionFee(num) {
    return formatString(getString("TOTAL_TRANSACTION_FEE", "Total Transaction Fee: {num}"), { num });
  }

  get ReminderForTransactionFee() {
    return getString(
      "MAKE_PAYMENT_FLYOUT_REMINDER_FOR_TRANACTION_FEE",
      "THERE MAY BE A TRANSACTION FEE APPLIED TO COVER CREDIT CARD PROCESSING COSTS."
    );
  }

  TotalPromisedAmount(num) {
    return formatString(getString("TOTAL_PROMISED_AMOUNT", "Total promised amount: {num}"), { num });
  }
  TotalDisputedAmount(num) {
    return formatString(getString("TOTAL_DISPUTED_AMOUNT", "Total disputed amount: {num}"), { num });
  }
  TotalInvoicedAmount(num) {
    return formatString(getString("TOTAL_INVOICED_AMOUNT", "Total invoiced amount: {num}"), { num });
  }
  get PayFrom() {
    return getString("PAY_FROM", "Pay from");
  }
  get ExportInvoices() {
    return getString("EXPORT_INVOICES", "Export Invoices");
  }
  get DownloadInvoices() {
    return getString("DOWNLOAD_INVOICES", "Download Invoices");
  }
  get AddNewPaymentMethod() {
    return getString("ADD_NEW_PAYMENT_METHOD", "Add a new payment method");
  }
  get DeletePaymentMethod() {
    return getString("DELETE_PAYMENT_METHOD", "Delete Payment Method");
  }
  get EditPaymentMethod() {
    return getString("EDIT_PAYMENT_METHOD", "Edit Payment Method");
  }
  get ManagePaymentMethods() {
    return getString("MANAGE_PAYMENT_METHODS", "Manage payment methods");
  }
  get NextPage() {
    return getString("NEXT_PAGE", "Next Page");
  }
  get PreviousPage() {
    return getString("PREVIOUS_PAGE", "Previous Page");
  }
  get LastPage() {
    return getString("LAST_PAGE", "Last Page");
  }
  get FirstPage() {
    return getString("FIRST_PAGE", "First Page");
  }
  get Status() {
    return getString("STATUS", "Status");
  }
  get Reply() {
    return getString("REPLY", "Reply");
  }
  get Subject() {
    return getString("SUBJECT", "Subject");
  }
  get NoSubject() {
    return getString("NO_SUBJECT", "(No subject)");
  }
  get Ago() {
    return getString("AGO", "Ago");
  }
  get From() {
    return getString("FROM", "From");
  }
  get To() {
    return getString("TO", "To");
  }
  get Date() {
    return getString("DATE", "Date");
  }
  get Country() {
    return getString("COUNTRY", "Country");
  }
  get Password() {
    return getString("PASSWORD", "Password");
  }
  get Confirm() {
    return getString("CONFIRM", "Confirm");
  }
  get Email() {
    return getString("EMAIL", "Email");
  }
  get DropFilesHere() {
    return getString("DROP_FILES_HERE", "Drop files here.");
  }
  get AttachFile() {
    return getString("ATTACH_FILE", "Attach file");
  }
  get Attach() {
    return getString("ATTACH", "Attach");
  }
  get ChooseLibraryFiles() {
    return getString("CHOOSE_LIBRARY_FILES", "Choose files from your library.");
  }
  get NothingToShowHere() {
    return getString("NOTHING_TO_SHOW_HERE", "Nothing to show here.");
  }
  get NoCompanyDocuments() {
    return getString("NO_COMPANY_DOCUMENTS", "Nothing here yet. First upload files from your computer.");
  }
  get NoFilesSelected() {
    return getString("NO_FILES_SELECTED", "No files selected");
  }
  get AttachFromComputer() {
    return getString("ATTACH_FROM_COMPUTER", "Attach files from computer");
  }
  get AddFilesToSharedDocuments() {
    return getString("ADD_FILES_TO_SHARED_DOCUMENTS", "Add files to Shared Documents");
  }
  get UploadAttachment() {
    return getString("UPLOAD_ATTACHMENT", "Upload attachment");
  }
  get UploadingAttachments() {
    return getString("UPLOADING_ATTACHMENTS", "Uploading attachments...");
  }
  get SelectUploadFile() {
    return getString("SELECT_UPLOAD_FILE", "Select files to upload");
  }
  get Messages() {
    return getString("MESSAGES", "Messages");
  }
  get Completed() {
    return getString("COMPLETED", "Completed");
  }
  get DateAdded() {
    return getString("DATE_ADDED", "Date added");
  }
  get SharedDocuments() {
    return getString("SHARED_DOCUMENTS", "Shared Documents");
  }
  get Template() {
    return getString("TEMPLATE", "Template");
  }
  get SignUp() {
    return getString("SIGN_UP", "Sign Up");
  }
  get UnappliedAmount() {
    return getString("UNAPPLIED_AMOUNT", "Unapplied Amount");
  }
  get PendingPayment() {
    return getString("PENDING_PAYMENT", "Pending payment");
  }

  get PartialPayment() {
    return getString("PARTIAL_PAYMENT", "Partial payment");
  }

  get PaymentPending() {
    return getString("PAYMENT_PENDING", "Payment pending");
  }

  get PaymentInformation() {
    return getString("PAYMENT_INFORMATION", "Payment information");
  }

  TotalUnappliedOrPendingPayments(total) {
    return formatString(
      getString(
        "TOTAL_UNAPPLIED_OR_PENDING_PAYMENTS",
        "You have unapplied and/or pending payments totaling {total} contributing to your total account balance."
      ),
      {
        total
      }
    );
  }
  get Message() {
    return getString("MESSAGE", "Message");
  }
  get Close() {
    return getString("CLOSE", "Close");
  }
  get Username() {
    return getString("USERNAME", "Username");
  }

  get CreatedBy() {
    return getString("CREATED_BY", "Created by");
  }

  get ForgotPassword() {
    return getString("FORGOT_PASSWORD", "Forgot your password?");
  }
  get LoginBlurb1() {
    return getString("LOGIN_BLURB_1", "Spend less time in email and more time on your books.");
  }
  get LoginBlurb2() {
    return getString(
      "LOGIN_BLURB_2",
      "Lockstep is an email solution designed for the way accounting professionals want to work."
    );
  }
  get DontHaveAnAccount() {
    return getString("DONT_HAVE_AN_ACCOUNT", "Don't have an account?");
  }
  get Sent() {
    return getString("SENT", "Sent");
  }
   SentCount(count) {
    return formatString(getString("SENT", "Sent({count})"),{count});
  }
  get SendAMessage() {
    return getString("SEND_A_MESSAGE", "Send a message");
  }
  get MessageReceived() {
    return getString("MESSAGE_RECEIVED", "New message received");
  }
  get SendingMessage() {
    return getString("SENDING_MESSAGE", "Sending message");
  }
  get SentMessage() {
    return getString("SENT_MESSAGE", "Message sent");
  }
  get SendMessageFailed() {
    return getString("SEND_MESSAGE_FAILED", "Sending the message failed");
  }
  get Archive() {
    return getString("ARCHIVE", "Archive");
  }
    ArchiveCount(count) {
    return  formatString(getString("ARCHIVE", "Archive({count})"),{count});
  }
  get MoveToInbox() {
    return getString("MOVE_TO_INBOX", "Move to inbox");
  }
  InboxCount(count) {
    return formatString(getString("INBOX_COUNT", "Inbox ({count})"), { count });
  }
  get Inbox() {
    return getString("INBOX", "Inbox");
  }
  get EmptyInboxMessages() {
    return getString("EMPTY_INBOX_MESSAGES", "You have no messages in this inbox.");
  }
  get EmptyInboxDocuments() {
    return getString("EMPTY_INBOX_DOCUMENTS", "You have no documents in this inbox.");
  }
  get EmptyStatements() {
    return getString("EMPTY_STATEMENTS", "There are no statements to show.");
  }
  get NoWorkspaceAccess() {
    return getString("NO_WORKSPACE_ACCESS", "You don't have access to any workspaces for your default company:");
  }
  get ContactAnAdministrator() {
    return getString("CONTACT_AN_ADMINISTRATOR", "Contact one of your account administrators to grant you access.");
  }
  get UhOh() {
    return getString("UH_OH", "Uh Oh.");
  }
  get SomethingWentWrong() {
    return getString("SOMETHING_WENT_WRONG", "Something went wrong on our side.");
  }
  get OurEngineersHaveBeenNotified() {
    return getString("OUR_ENGINEERS_HAVE_BEEN_NOTIFIED", "Our engineers have been notified to fix this issue.");
  }
  get TryAgainInAWhile() {
    return getString("TRY_AGAIN_IN_A_WHILE", "Try again in a little while or");
  }
  get ReturnToHomePage() {
    return getString("RETURN_TO_HOME_PAGE", "return to home page.");
  }
  get Type() {
    return getString("TYPE", "Type");
  }
  get LoadMore() {
    return getString("LOAD_MORE", "click here to load more");
  }
  get Unknown() {
    return getString("UNKNOWN", "Unknown");
  }
  get Remove() {
    return getString("REMOVE", "Remove");
  }
  get YoureOffline() {
    return getString("YOURE_OFFLINE", "You're offline. Results may not be up to date.");
  }
  get NoNetworkMessage() {
    return getString("NO_NETWORK_MESSAGE", "Your computer seems to be offline.  Check your network connection.");
  }
  get ErrorDialogTitle() {
    return getString("ERROR_DIALOG_TITLE", "Whoops! Something went wrong!");
  }
  get Contacts() {
    return getString("CONTACTS", "Contacts");
  }
  get InvoiceNumber() {
    return getString("INVOICE_NUMBER", "Invoice Number");
  }
  get NoStatementSearchResultsMessage() {
    return getString(
      "NO_STATEMENT_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for an invoice number."
    );
  }
  get NoMessageSearchResultsMessage() {
    return getString(
      "NO_STATEMENT_SEARCH_RESULTS_MESSAGE",
      "Oops! We can't find what you're looking for. You can search for a subject or message content."
    );
  }
  SeeAllInvoiceResults(searchTerm) {
    return formatString(getString("SEE_ALL_INVOICE_RESULTS", 'See all results for invoices with "{searchTerm}"'), {
      searchTerm
    });
  }
  SeeAllPaymentsResults(searchTerm) {
    return formatString(getString("SEE_ALL_PAYMENTS_RESULTS", 'See all results for payments with "{searchTerm}"'), {
      searchTerm
    });
  }
  SeeAllMessageResults(searchTerm) {
    return formatString(getString("SEE_ALL_MESSAGES_RESULTS", 'See all results for messages with "{searchTerm}"'), {
      searchTerm
    });
  }
  get TransactionId() {
    return getString("TRANSACTION_ID", "Transaction Id");
  }
  get TransactionType() {
    return getString("TRANSACTION_TYPE", "Transaction Type");
  }
  get AmountDue() {
    return getString("AMOUNT_DUE", "Amount Due");
  }
  get PaymentAmt() {
    return getString("PAYMENT_AMT", "Payment Amt");
  }
  get UnappliedAmt() {
    return getString("UNAPPLED_AMT", "Unapplied Amt");
  }
  get Account() {
    return getString("ACCOUNT", "Account");
  }
  get AmountPaid() {
    return getString("AMOUNT_PAID", "Amount Paid");
  }
  get InvoiceDate() {
    return getString("INVOICE_DATE", "Invoice Date");
  }
  get Download() {
    return getString("DOWNLOAD", "Download");
  }
  get DownloadAttachments() {
    return getString("DOWNLOAD_ATTACHMENTS", "Download attachments");
  }
  get Document() {
    return getString("DOCUMENT", "Document");
  }
  get HostAddress() {
    return getString("HOST_ADDRESS", "Host Address");
  }
  get HostAddressExample() {
    return getString("HOST_ADDRESS_EXAMPLE", "imap.domain.com");
  }
  get Port() {
    return getString("PORT", "Port");
  }
  get Active() {
    return getString("ACTIVE", "Active");
  }
  get DeleteEmailFromServerAfterImport() {
    return getString("DELETE_EMAIL_FROM_SERVER_AFTER_IMPORT", "Delete email from server after import?");
  }
  get Day() {
    return getString("DAY", "Day");
  }
  get Week() {
    return getString("WEEK", "Week");
  }
  get Month() {
    return getString("MONTH", "Month");
  }
  get Custom() {
    return getString("CUSTOM", "Custom");
  }
  get ImportAllMailFromPast() {
    return getString("IMPORT_ALL_MAIL_FROM_PAST", "Import all mail from past");
  }
  get Days() {
    return getString("DAYS", "Days");
  }
  get ImapSettings() {
    return getString("IMAP_SETTINGS", "IMAP Settings");
  }
  get Phone() {
    return getString("PHONE", "Phone");
  }
  get Fax() {
    return getString("FAX", "Fax");
  }
  get MailingAddress() {
    return getString("MAILING_ADDRESS", "Mailing Address");
  }
  get Notes() {
    return getString("NOTES", "Notes");
  }
  get ContactUs() {
    return getString("CONTACT_US", "Contact Us");
  }
  get YourInformation() {
    return getString("YOUR_INFORMATION", "Your Information");
  }
  get NoContactInfoYet() {
    return getString("NO_CONTACT_INFO_YET", "No contact info yet.");
  }
  get AddYourInformationSoWeKnowBestWayToReachYou() {
    return getString(
      "ADD_YOUR_INFORMATION_SO_WE_KNOW_BEST_WAY_TO_REACH_YOU",
      "Add your information so we know the best way to reach you."
    );
  }
  get AddNewContact() {
    return getString("ADD_NEW_CONTACT", "Add a new contact");
  }
  get Pay() {
    return getString("PAY", "Pay");
  }
  get SendMessage() {
    return getString("SEND_MESSAGE", "Send message");
  }
  get Dispute() {
    return getString("DISPUTE", "Dispute");
  }
  get PromiseToPay() {
    return getString("PROMISETOPAY", "Promise to pay");
  }
  get Export() {
    return getString("EXPORT", "Export");
  }
  get MarkAsRead() {
    return getString("MARK_AS_READ", "Mark as read");
  }
  get MarkAsUnread() {
    return getString("MARK_AS_UNREAD", "Mark as unread");
  }
  get Due_Date() {
    return getString("DUE_DATE", "Due date");
  }
  get TransactionDate() {
    return getString("TRANSACTION_DATE", "Transaction date");
  }
  get Owner() {
    return getString("OWNER", "Owner");
  }
  get DateUploaded() {
    return getString("DATE_UPLOADED", "Date uploaded");
  }
  ShowNItems(numItems) {
    return formatString(getString("SHOW_N_ITEMS", "Show {numItems} items"), { numItems });
  }
  get DocumentUploading() {
    return getString("DOCUMENT_UPLOADING", "Document uploading");
  }
  get DocumentUploaded() {
    return getString("DOCUMENT_UPLOADED", "Document uploaded");
  }
  get DocumentFailedToUpload() {
    return getString("DOCUMENT_FAILED_TO_UPLOAD", "Document failed to upload");
  }
  get ZeroInvoices() {
    return getString("ZERO_INVOICES", "0 Invoices");
  }
  get DisputeInvoices() {
    return getString("DISPUTED_INVOICES", "Dispute invoices");
  }
  get Reason() {
    return getString("REASON", "Reason");
  }
  get MessageOptional() {
    return getString("MESSAGE_OPTIONAL", "Message (optional)");
  }
  get IncorrectInformation() {
    return getString("INCORRECT_IFORMATION", "Incorrect information");
  }
  get ExpectedPaymentDate() {
    return getString("EXPECTED_PAYMENT_DATE", "Expected payment date");
  }
  get PaymentSuccessfulMessage() {
    return getString("PAYMENT_SUCCESSFUL_MESSAGE", "Nice work, payment successful!");
  }
  get SuccessfulMessage() {
    return getString("SUCCESSFUL_MESSAGE", "Nice work!");
  }
  get PaymentFailureMessage() {
    return getString("PAYMENT_FAILURE_MESSAGE", "Oops, something's not right!");
  }
  get FailureMessage() {
    return getString("FAILURE_MESSAGE", "Oops, something's not right!");
  }
  YourTransactionIdIs(transactionId) {
    return formatString(
      getString(
        "YOUR_TRANSACTION_ID_IS",
        "Your transaction ID is {transactionId}. If this opened a new window, you may close it."
      ),
      {
        transactionId
      }
    );
  }
  PaymentErrorIs(message) {
    message = decodeURLQueryString(message);
    return formatString(
      getString("PAYMENT_ERROR_IS", "It looks like {message}. Please go back and re-enter your payment information."),
      { message }
    );
  }
  get YourPaymentMethodWasAddedSuccessfully() {
    return getString(
      "PAYMENT_METHOD_SUCCESSFUL",
      "Your payment method was added successfully. If this opened a new window, you may close it."
    );
  }
  InvoicesPaid(num) {
    return formatString(getString("INVOICES_PAID", "Invoices paid ({num})"), { num });
  }
  InvoicesSelectedFor(num) {
    return formatString(getString("INVOICES_SELECTED_FOR", "Invoices selected ({num})"), { num });
  }
  get PastDue() {
    return getString("PAST_DUE", "Past due");
  }
  get ExportingTable() {
    return getString("EXPORTING_TABLE", "Exporting table");
  }
  get ExportComplete() {
    return getString("EXPORT_COMPLETE", "Export complete");
  }
  get ExportTableFailed() {
    return getString("EXPORT_TABLE_FAILED", "Export of table failed");
  }
  get PhoneNumber() {
    return getString("PHONE_NUMBER", "Phone number");
  }
  get MakePrimaryContact() {
    return getString("MAKE_PRIMARY_CONTACT", "Make primary contact");
  }
  get Person() {
    return getString("PERSON", "Person");
  }
  get Business() {
    return getString("BUSINESS", "Business");
  }
  get WereOnIt() {
    return getString("WERE_ON_IT", "We're on it!");
  }
  get OurSiteIsExperiencingSomeIssues() {
    return getString(
      "OUR_SITE_IS_EXPERIENCING_SOME_ISSUES",
      "Our site is currently experiencing some issues and we are working hard to fix them."
    );
  }
  get ConnectedBy() {
    return getString("CONNECTED_BY", "connected by");
  }
  get Disputed() {
    return getString("DISPUTED", "Disputed");
  }
  get Promised() {
    return getString("PROMISED", "Promised");
  }
  get ReasonForDispute() {
    return getString("REASON_FOR_DISPUTE", "Reason for dispute");
  }
  get SubmittingPromiseToPay() {
    return getString("SUBMITTING_PROMISE_TO_PAY", "Submitting promise to pay");
  }
  get PromiseToPaySubmitted() {
    return getString("PROMISE_TO_PAY_SUBMITTED", "Promise to pay submitted");
  }
  get FailedSubmittingPromiseToPay() {
    return getString("FAILED_SUBMITTING_PROMISE_TO_PAY", "Failed to submit promise to pay");
  }
  get SubmittingDispute() {
    return getString("SUBMITTING_DISPUTE", "Submitting dispute");
  }
  get DisputeSubmitted() {
    return getString("DISPUTE_SUBMITTED", "Dispute submitted");
  }
  get FailedSubmittingDispute() {
    return getString("FAILED_SUBMITTING_DISPUTE", "Failed to submit dispute");
  }
  get Primary() {
    return getString("PRIMARY", "Primary");
  }
  get DownloadInProgress() {
    return getString("DOWNLOAD_IN_PROGRESS", "Download in progress");
  }
  get DownloadComplete() {
    return getString("PRIMARY", "Download complete");
  }
  get DownloadFailed() {
    return getString("PRIMARY", "Download failed");
  }
  get ContactSubmitting() {
    return getString("CONTACT_SUBMITTING", "Contact submitting");
  }
  get ContactSubmitted() {
    return getString("CONTACT_SUBMITTED", "Contact submitted");
  }
  get ContactSubmitFailed() {
    return getString("CONTACT_SUBMIT_FAILED", "Contact submit failed");
  }
  get ContactDeleting() {
    return getString("CONTACT_DELETING", "Contact deleting");
  }
  get ContactDeleted() {
    return getString("CONTACT_DELETED", "Contact deleted");
  }
  get ContactDeleteFailed() {
    return getString("CONTACT_DELETE_FAILED", "Contact delete failed");
  }
  get Reset() {
    return getString("RESET", "Reset");
  }
  NTransactions(n) {
    return formatString(getString("N_TRANSACTIONS", "{n} transactions"), { n });
  }
  NApplications(n) {
    return formatString(getString("N_APPLICATIONS", "{n} applications"), { n });
  }
  get Apply() {
    return getString("APPLY", "Apply");
  }
  SelectInvoicesForPaymentApplication(paymentId) {
    return formatString(
      getString("SELECT_INVOICES_FOR_PAYMENT_APPLICATION", "Select invoices to apply to payment {paymentId}"),
      { paymentId }
    );
  }
  get OneApplication() {
    return getString("ONE_APPLICATION", "1 application");
  }
  InvoiceNAttachments(n) {
    return formatString(getString("INVOICE_N_ATTACHMENTS", "Invoice {n} - Attachments"), { n });
  }
  get InvoiceAttachments() {
    return getString("INVOICE_ATTACHMENTS", "Invoice attachments");
  }
  get CompanyStreetAddress() {
    return getString("COMPANY_STREET_ADDRESS", "Company street address");
  }
  get JobTitle() {
    return getString("JOB_TITLE", "Job title");
  }
  get StateRegion() {
    return getString("STATE_REGION", "State/region");
  }
  get ZipCode() {
    return getString("ZIP_CODE", "Zip code");
  }
  get AutomaticPaymentsAreOn() {
    return getString("AUTOMATIC_PAYMENTS_ARE_ON", "Automatic payments are on");
  }
  get AutomaticPaymentsAreOff() {
    return getString("AUTOMATIC_PAYMENTS_ARE_OFF", "Automatic payments are off");
  }
  get WeWillPayAllOpenInvoicesOnTheirGivenPaymentDates() {
    return getString(
      "WE_WILL_PAY_ALL_OPEN_INVOICES_ON_THEIR_GIVEN_PAYMENT_DATE",
      "We will pay all open invoices on their given payment dates."
    );
  }
  get WhenEnabledWeWillPayAllOpenInvoicesOnTheirGivenPaymentDates() {
    return getString(
      "WHEN_ENABLED_WE_WILL_PAY_ALL_OPEN_INVOICES_ON_THEIR_GIVEN_PAYMENT_DATE",
      "When enabled, we will pay all open invoices on their given payment dates."
    );
  }
  get ManageAutomaticPayments() {
    return getString("MANAGE_AUTOMATIC_PAYMENTS", "Manage automatic payments");
  }
  get WhenPaymentIsMadeHowWouldYouLikeToBeNotified() {
    return getString(
      "WHEN_PAYMENT_IS_MADE_HOW_WOULD_YOU_LIKE_TO_BE_NOTIFIED",
      "When a payment is made, how would you like to be notified?"
    );
  }
  get PortalMessage() {
    return getString("PORTAL_MESSAGE", "Portal message");
  }
  get EmailDefaultAddressOnFile() {
    return getString("EMAIL_DEFAULT_ADDRESS_ON_FILE", "Email (default address on file)");
  }
  get AutomaticPayments() {
    return getString("AUTOMATIC_PAYMENTS", "Automatic payments");
  }
  get AutoPayConfigurationError() {
    return getString("AUTO_PAY_CONFIG_ERROR", "There was a problem saving your auto pay settings. Please try again.");
  }
  get WeWillPayAllOpenInvoicesOnTheirGivenDueDateUsingThePaymentMethodSelectedAbove() {
    return getString(
      "WE_WILL_PAY_ALL_OPNE_INVOICES_ON_THEIR_GIVEN_DUE_DATE_USING_THE_PAYMENT_METHOD_SELECTED_ABOVE",
      "We will pay all open invoices on their given due date using the payment method selected above."
    );
  }
  get CheckingForUnappliedPayments() {
    return getString("CHECKING_FOR_UNAPPLIED_PAYMENTS", "Checking for unapplied payments...");
  }
  get PortalClosedInvoicesEmpty() {
    return getString("PORTAL_CLOSED_INVOICES_EMPTY", "Once you've closed an invoice it will show up here.");
  }
  get PortalClosedInvoicesEmptyNew() {
    return getString(
      "PORTAL_CLOSED_INVOICES_EMPTY_NEW",
      "You don't have any closed invoices, once an invoice is closed it will show up here."
    );
  }
  get NothingToSeeHere() {
    return getString("NOTHING_TO_SEE_HERE", "Nothing to see here!");
  }
  get NiceWorkYoureAllCaughtUp() {
    return getString("NICE_WORK_YOURE_ALL_CAUGHT_UP", "Nice work, you're all caught up!");
  }
  get PortalOpenInvoicesEmpty() {
    return getString("PORTAL_OPEN_INVOICES_EMPTY", "You have 0 open invoices.");
  }
  get PortalOpenInvoicesEmptyNew() {
    return getString(
      "PORTAL_OPEN_INVOICES_EMPTY_NEW",
      "You don’t have any open invoices. Once you have open invoices to pay, they will show up here. You can use the navigation bar above to see closed invoices, payments, and more."
    );
  }
  get PortalDocumentsEmpty() {
    return getString(
      "PORTAL_DOCUMENTS_EMPTY",
      "Upload documents like your W9 so you can keep everything in one place."
    );
  }
  get PortalPaymentsEmpty() {
    return getString("PORTAL_PAYMENTS_EMPTY", "Once you've made a payment it will show up here.");
  }
  get PortalPaymentsEmptyNew() {
    return getString(
      "PORTAL_PAYMENTS_EMPTY_NEW",
      "You haven't made any payments, once a payment is made it will show up here."
    );
  }
  get YourInboxIsEmpty() {
    return getString("YOUR_INBOX_IS_EMPTY", "Your inbox is empty!");
  }
  get Communications() {
    return getString("COMMUNICATIONS", "Communications");
  }
  get TotalUnappliedPaymentAmount() {
    return getString("TOTAL_UNAPPLIED_PAYMENT_AMOUNT", "Total unapplied payment amount");
  }
  HelloCompanyNameWelcomeBack(companyName) {
    return formatString(getString("HELLO_COMPANY_NAME_WELCOME_BACK", "Hello {companyName}, welcome back."), {
      companyName
    });
  }
  HelloCompanyNameIdWelcomeBack(companyName, companyId) {
    return formatString(
      getString("HELLO_COMPANY_NAME_ID_WELCOME_BACK", "Hello {companyName} ({companyId}), welcome back."),
      { companyName, companyId }
    );
  }
  get YourSessionHasExpired() {
    return getString("YOUR_SESSION_HAS_EXPIRED", "Your session has expired.");
  }
  get PortalSessionExpired() {
    return getString(
      "PORTAL_SESSION_EXPIRED",
      "For security purposes, we end a session after 12 hours. Please click the link in your email to start a new session."
    );
  }
  get MakePaymentFlyoutFilteredOutCreditMemo() {
    return getString(
      "MAKE_PAYMENT_FLYOUT_FILTERED_OUT_CREDIT_MEMO",
      "We noticed you selected a credit memo for this payment. Payments on credit memos cannot be made, so that selection is not shown here."
    );
  }
  get NA() {
    return getString("NA", "N/A");
  }
  get OopsThereIsAnIssueAccessingYourAccount() {
    return getString(
      "OOPS_THERE_IS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT",
      "Oops! There's an issue with accessing your account."
    );
  }
  get OopsYourAccountHasMissingData() {
    return getString(
      "OOPS_YOUR_ACCOUNT_HAS_MISSING_DATA",
      "Oops! It looks like you are using an invalid account."
    );
  }
  get LogInAsADifferentUser() {
    return getString(
      "LOG_IN_AS_A_DIFFERENT_USER",
      "The account you are trying to access is missing important data. Please go back and try another account or contact our support team at support@lockstep.io."
    );
  }
  get PleaseContactSupportToReportThisError() {
    return getString(
      "PLEASE_CONTACT_SUPPORT_TO_REPORT_THIS_ERROR",
      "Please contact support@lockstep.io to report this error and get it fixed."
    );
  }

  get GetLanguagesFailure() {
    return getString(
      "GET_LANGUAGES_FAILURE",
      "Oops! There was an issue getting all languages. Please refresh the page. If this issue persist, please contact us at support@lockstep.io."
    );
  }

  get GetDisputeCodeFailure() {
    return getString(
      "GET_DISPUTECODE_FAILURE",
      "Oops! There was an issue getting all disputecodes. Please refresh the page. If this issue persist, please contact us at support@lockstep.io."
    );
  }

  get Language() {
    return getString("LANGUAGE", "Language");
  }
  get LastUpdated() {
    return getString("LAST_UPDATED", "Last updated");
  }
  get LoadingDisputeCodes() {
    return getString("LOADING_DISPUTECODES", "Loading Dispute Codes...");
  }
  get DocumentType() {
    return getString("DOCUMENT_TYPE", "Document Type");
  }
  get File() {
    return getString("FILE", "Files");
  }
  get Logo() {
    return getString("LOGO", "Company Logo");
  }
  TransactionsAppliedToInvoice(invoice) {
    return formatString(getString("TRANSACTIONS_APPLIED_TO_INVOICE", "Transactions applied to invoice #{invoice}"), {
      invoice
    });
  }

  InvoicesAppliedToPayment(payment) {
    return formatString(getString("INVOICES_APPLIED_TO_PAYMENT", "Invoices applied to payment {payment}"), {
      payment
    });
  }
  get GetCurrenciesFailure() {
    return getString(
      "GET_CURRENCIES_FAILURE",
      "Oops! There was an issue getting currencies. Please refresh the page. If this issue persist, please contact us at support@lockstep.io."
    );
  }
  get JustNow() {
    return getString("JUST_NOW", "just now");
  }
  get SnoozingActivity() {
    return getString("SNOOZING_ACTIVITY", "Snoozing activity");
  }
  get SnoozedActivity() {
    return getString("SNOOZED_ACTIVITY", "Snoozed activity");
  }
  get FailedToSnoozeActivity() {
    return getString("FAILED_TO_SNOOZE_ACTIVITY", "Failed to snooze activity");
  }
  get UnsupportedBrowser() {
    return getString("UNSUPPORTED_BROWSER", "Oops! It looks like you are using an unsupported browser.");
  }
  get UnsupportedBrowserText() {
    return getString(
      "UNSUPPORTED_BROWSER_TEXT",
      "Our team is working hard to support Safari but right now our site won’t work properly on this browser. We recommend switching to Chrome or Firefox so you can access the full experience."
    );
  }
  get FetchingPopulatedTemplate() {
    return getString("FETCHING_POPULATED_TEMPLATE_NAME", "Fetching template");
  }
  get SelectMessageTemplate() {
    return getString("SELECT_MESSAGE_TEMPLATE", "Select message template");
  }
  get AddVendors() {
    return getString("ADD_VENDORS", "Add vendors");
  }
  get RequestToAddVendorsSubmitted() {
    return getString("REQUEST_TO_ADD_VENDORS_SUBMITTED", "Request to add vendors submitted");
  }
  get TheFollowingValidationErrorsOccurred() {
    return getString("THE_FOLLOWING_VALIDATION_ERRORS_OCCURRED", "The following validation errors occurred:");
  }
  get JoinLockstep() {
    return getString("JOIN_LOCKSTEP", "Join Lockstep");
  }
  get ProcessMoreInvoices() {
    return getString("PROCESS_MORE_INVOICECS", "Process more invoices");
  }
  get PayAllInvoicesOnTheirGivenDueDates() {
    return getString("PAY_ALL_INVOICES_ON_THEIR_GIVEN_DUE_DATES", "Pay all open invoices on their given due dates");
  }
  get PayTheTotalOpenBalanceOnThe() {
    return getString("PAY_THE_TOTAL_OPEN_BALANCE_ON_THE", "Pay the total open balance on the");
  }
  get PayThePastDueBalanceOnThe() {
    return getString("PAY_THE_PAST_DUE_BALANCE_ON_THE", "Pay the past due balance on the");
  }
  get EnterTheDayOfTheMonth() {
    return getString("PLEASE_ENTER_THE_DAY_OF_THE_MONTH", "Please enter the day for the month");
  }
  get DayOfEveryMonth() {
    return getString("DAY_OF_EVERY_MONTH", "Day of every month");
  }
  get GiveFeedback() {
    return getString("GIVE_FEEDBACK", "Give feedback");
  }
  get YourPaymentMethodWasDeclined() {
    return getString("YOUR_PAYMENT_METHOD_WAS_DECLINED", "Your payment method was declined");
  }
  get SelectOkToConfirmPayment() {
    return getString("SELECT_OK_TO_CONFIRM_PAYMENT", "We noticed you selected a credit memo for this payment.  Payments with credit memo applications cannot be made when on All Accounts.  Select Ok to continue without the credit memo selected or select cancel to select an individual account for the credit memo application.");
  }
  YouSelectedNumberofInvoices(numOfSelectedKeys, selectedType) {
    return formatString(
      getString(
        "YOU_SELECTED_NUMBER_OF_INVOICES",
        "All <b>{numOfSelectedKeys}</b> {selectedType} on this page are selected."
      ),
      { numOfSelectedKeys, selectedType }
    );
  }
  SelectAllInvoicesInPage(pageName, maxRows, selectedType) {
    return formatString(
      getString("SELECT_ALL_INVOICES_IN_PAGE", "Select all {maxRows} {selectedType} in {pageName}."),
      {
        pageName,
        maxRows,
        selectedType
      }
    );
  }
  get ClearSelection() {
    return getString("CLEAR_SELECTION", "Clear selection.")
  }
  AllOpenInvoicesSelected(numOfSelectedKeys, pageName, selectedType) {
    return formatString(
      getString("ALL_OPEN_INVOICES_SELECTED", "All <b>{numOfSelectedKeys}</b> {selectedType} in {pageName} are selected."),
      {
        numOfSelectedKeys,
        pageName,
        selectedType
      }
    )
  }
  get DeleteAutoPayMethodConfirm() {
    return getString(
      "DELETE_AUTO_PAY_METHOD_CONFIRM",
      "Are you sure? This payment method is used for automatic payments. If you delete this payment method then we will turn off automatic payments."
    );
  }
  get TruncateLimit() {
    return getString("TRUNCATE_STRING_LIMIT", 68)
  }
  get MissingPaymentMethod() {
    return getString("MISSING_PAYMENT_METHOD", "Please select a payment method.");
  }
  get DropdownWidth() {
    return getString("GET_DROPDOWN_WIDTH", 690)
  }
  get DuplicatePayment() {
    return getString("DUPLICATE_PAYMENT", "Duplicate Payment");
  }
  get Info() {
    return getString("INFO", "Info");
  }
  get PaymentMethodDeletedSuccessfully() {
    return getString("PAYMENT_METHOD_DELETED_SUCCESSFULLY", "Payment method deleted successfully.");
  }
  get SearchByInvoiceOrCustomerPO() {
    return getString("SEARCH_INVOICE_OR_CUSTOMER_PO", "Search by Invoice # or Customer PO");
  }
  get InvoiceNumberWithSymbol() {
    return getString("INVOICE_NUMBER_WITH_SYMBOL", "Invoice #");
  }
  get AndCustomerPO() {
    return getString("AND_CUSTOMER_PO", "& Customer PO");
  }
  SeeAllResults(searchTerm) {
    return formatString(getString("SEE_ALL_RESULTS", 'See all results with "{searchTerm}"'), {
      searchTerm
    });
  }

  get TotalAmountSelected() {
    return getString("TOTAL_AMOUNT_SELECTED", "Total Amount Selected");
  }

  get DiscountAmount(){
    return getString("Discount_Amount" , "Discount Amount");
  }

  get DiscountDate(){
    return getString("Discount_Date" , "Discount Date");
  }

  TotalDiscountAmount(num) {
    return formatString(getString("TOTAL_DISCOUNT_AMOUNT", "Total Discount Amount : {num}"), { num });
  }

  get AllowAutoPayForChildAccounts (){
    return getString("ALLOW_AUTO_PAYMENTS_FOR_CHILD_ACCOUNTS", "Allow AutoPay For Child Accounts");
  }

  get SomeOfTheChildAccountsAreEnabledInfoMessage() {
    return getString("SOME_OF_THE_CHILD_ACCOUNTS_ARE_ENABLED_INFO_MESSAGE", `Some of the child accounts are enabled for AutoPay, they will be disabled if this option is
    selected. Do you still want to continue?`);
  }

  get AutoPaymentsAreTurnedOnByParentInfoMessage() {
     return getString(
        "AUTO_PAY_PAYMENTS_ARE_TURNED_ON_BY_PARENT",
        "AutoPay Payments are turned on by <b>“Parent/Self”</b>. Please connect with the Parent in case you need to make changes."
      )
  }

   AutoPaymentAreTurnedOnAndChangesAreRestrictedByVendorInfoMessage(autoPayEnabled,vendorName) {
    return formatString(getString("",`AutoPay Payments are turned ${autoPayEnabled?'on':'off'} and changes have been restricted by Vendor <b>“${vendorName}”</b> . Please connect with the vendor in case you need to make changes.`))
  }

  get AutoPayConfigurationChangesAreNotAllowed() {
    return getString("AUTO_PAY_CONFIGURATION_CHANGES_ARE_NOT_ALLOWED", "AutoPay configuration changes are not allowed for a specific child account.")
  }
}

const resources = new Resources();

export default resources;